import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

export * from "./sdk";

export class Graph {
  public readonly client = new ApolloClient({
    link: new HttpLink({
      uri: "https://v308r9x955.execute-api.us-east-1.amazonaws.com/live/x/graphql",
      headers: {
        "X-Api-Key": "ksdYMvMtSta4Yvftt5UhF8o3rZSfLoUS1RRKw9Uu",
      },
      fetch,
    }),
    cache: new InMemoryCache(),
  });
}

const graph = new Graph();

export default graph;
