import React from "react";
import { StaticQuery, graphql } from "gatsby";
import {
  faGithub,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import SocialLink from "./SocialLink";

const SocialLinks: React.FC = () => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const social = data.site.siteMetadata.social;

      return (
        <React.Fragment>
          <SocialLink
            url={`https://www.github.com/${social.github}`}
            icon={faGithub}
          />
          <SocialLink
            url={`https://www.instagram.com/${social.instagram}`}
            icon={faInstagram}
          />
          <SocialLink
            url={`https://twitter.com/${social.twitter}`}
            icon={faTwitter}
          />
          <SocialLink url={`mailto:${social.email}`} icon={faEnvelope} />
        </React.Fragment>
      );
    }}
  />
);

const detailsQuery = graphql`
  query DefaultSocialLinksQuery {
    site {
      siteMetadata {
        social {
          github
          instagram
          twitter
          email
        }
      }
    }
  }
`;

export default SocialLinks;
