import React, { useState } from "react";
import styled, { css } from "styled-components";
import BurgerMenu from "react-burger-menu";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import * as colors from "@tleef/colors";

import transition from "../styles/transition";
import breakpoint from "../styles/breakpoint";

import Root from "./Root";
import { Theme } from "../types";

type ThemeNames = "light" | "dark";
type Themes = {
  [key in ThemeNames]: Theme;
};

const themes: Themes = {
  light: {
    color: {
      separator: colors.gray[200],
      primary: {
        plus: colors.white,
        main: colors.gray[100],
        minus: colors.gray[500],
      },
      secondary: {
        minus: colors.gray[700],
        main: colors.gray[900],
        plus: colors.black,
      },
    },
    breakpoint,
    transition,
  },
  dark: {
    color: {
      separator: colors.gray[800],
      primary: {
        plus: colors.black,
        main: colors.gray[900],
        minus: colors.gray[600],
      },
      secondary: {
        minus: colors.gray[400],
        main: colors.gray[100],
        plus: colors.white,
      },
    },
    breakpoint,
    transition,
  },
};

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.primary.minus};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 15px;
  transition: ${(props) => props.theme.transition.create()};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.color.secondary.minus};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 15px;
`;

interface ToggleProps {
  isActive?: boolean;
}

const Toggle = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.secondary.main};
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.theme.color.primary.minus};
  border-radius: 3px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      color: #fff;
      background: linear-gradient(105deg, #11998e, #38ef7d);
      border-color: #28cc84;
    `}

  & + & {
    margin-left: 10px;
  }
`;

const Layout: React.FC = ({ children }) => {
  let initialTheme: ThemeNames = "light";
  if (!isSSR()) {
    const cookieTheme = getCookie("tleef:theme");
    if (cookieTheme === "light" || cookieTheme === "dark") {
      initialTheme = cookieTheme;
    }
  }

  const [themeName, setTheme] = useState<ThemeNames>(initialTheme);

  const theme = themes[themeName];

  const menuStyles: Partial<BurgerMenu.Styles> = {
    bmBurgerBars: {
      background: theme.color.primary.minus,
    },
    bmBurgerButton: {
      zIndex: "2000",
      position: "fixed",
      width: "24px",
      height: "20px",
      left: "24px",
      top: "29px",
    },
    bmCross: {
      background: theme.color.primary.minus,
    },
    bmMenuWrap: {
      zIndex: "2100",
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.1), 0px 5px 8px 0px rgba(0,0,0,0.07), 0px 1px 14px 0px rgba(0,0,0,0.06)",
    },
    bmMenu: {
      background: theme.color.primary.main,
      transition: theme.transition.create(),
      padding: "40px 24px 0",
    },
    bmOverlay: {
      zIndex: "2000",
    },
  };

  const activeStyle = { color: theme.color.secondary.minus };

  return (
    <Root theme={theme}>
      <BurgerMenu.slide styles={menuStyles}>
        <StyledLink activeStyle={activeStyle} to="/">
          Home
        </StyledLink>
        <StyledLink activeStyle={activeStyle} to="/about">
          About
        </StyledLink>
        <div style={{ outline: "none" }}>
          <Row>
            <Toggle
              isActive={themeName === "dark"}
              onClick={() => {
                setCookie("tleef:theme", "dark");
                setTheme("dark");
              }}
            >
              <FontAwesomeIcon icon={faMoon} />
            </Toggle>
            <Toggle
              isActive={themeName === "light"}
              onClick={() => {
                setCookie("tleef:theme", "light");
                setTheme("light");
              }}
            >
              <FontAwesomeIcon icon={faSun} />
            </Toggle>
          </Row>
        </div>
      </BurgerMenu.slide>
      {children}
    </Root>
  );
};

export default Layout;

function setCookie(name: string, value: string, days?: number) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name: string) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function isSSR() {
  return typeof document === "undefined";
}