import React from "react";
import styled from "styled-components";
import Window from "@tleef/react-window";

import zIndex from "../styles/zIndex";

import Logo from "./Logo";
import Nav from "./Nav";

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.theme.color.primary.main};
  transition: ${(props) => props.theme.transition.create()};
  z-index: ${zIndex.appBar};
`;

interface State {
  scrolled: boolean;
  prevScroll: number;
  scrollDiff: number;
  hideNav: boolean;
}

export default class Header extends React.Component {
  private readonly bar = React.createRef<HTMLDivElement>();
  private readonly nav = React.createRef<HTMLDivElement>();

  state: State = {
    scrolled: false,
    prevScroll: 0,
    scrollDiff: 0,
    hideNav: false,
  };

  render() {
    return (
      <Window onScroll={this.onWindowScroll}>
        <Bar ref={this.bar}>
          <Logo shrink={this.state.scrolled} />
        </Bar>
        <Nav
          hide={this.state.hideNav}
          shadow={this.state.scrolled}
          ref={this.nav}
        />
      </Window>
    );
  }

  onWindowScroll = () => {
    let bar = this.bar.current;
    let nav = this.nav.current;

    if (!bar || !nav) {
      return;
    }

    let scrolled = this.state.scrolled;
    let prevScroll = this.state.prevScroll;
    let hideNav = this.state.hideNav;

    let scroll = window.scrollY;
    let scrollDiff = scroll - prevScroll;

    const state: Partial<State> = {
      prevScroll: scroll,
      scrollDiff,
    };

    if (!hideNav && scrollDiff > 0) {
      state.hideNav = true;
    } else if (hideNav && scrollDiff < 0) {
      state.hideNav = false;
    }

    if (!scrolled && scroll > 0) {
      state.scrolled = true;
      state.hideNav = true;
    } else if (scrolled && scroll <= 0) {
      state.scrolled = false;
      state.hideNav = false;
    }

    this.setState(state);
  };
}
