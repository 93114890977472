import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

interface Props {
  title?: string;
  type?: string;
  image?: string;
  url?: string;
  description?: string;
  lang?: string;
  meta?: Meta[];
  keywords?: string[];
}

type Meta =
  | {
      property: string;
      content: string;
    }
  | {
      name: string;
      content: string;
    };

const Seo: React.FC<Props> = ({
  title,
  type,
  image,
  url,
  description,
  lang = `en`,
  meta = [],
  keywords = [],
}) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        title = title
          ? `${title} - ${data.site.siteMetadata.siteName}`
          : data.site.siteMetadata.title;

        type = type || "website";

        description = description || data.site.siteMetadata.description;

        const optionalMeta = [];

        if (image) {
          optionalMeta.push({
            property: `og:image`,
            content: image,
          });

          optionalMeta.push({
            name: `twitter:image`,
            content: image,
          });
        }

        if (url) {
          optionalMeta.push({
            property: `og:url`,
            content: url,
          });
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:type`,
                content: type,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: "og:locale",
                content: "en_US",
              },
              {
                property: `og:site_name`,
                content: data.site.siteMetadata.siteName,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
            ]
              .concat(optionalMeta)
              .concat(meta)
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )}
          />
        );
      }}
    />
  );
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteName
      }
    }
  }
`;
