import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type HealthStatus = {
  __typename?: "HealthStatus";
  status: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  subscribe?: Maybe<Success>;
  createPostComment?: Maybe<PostComment>;
};

export type MutationSubscribeArgs = {
  email: Scalars["String"];
  source: Scalars["String"];
};

export type MutationCreatePostCommentArgs = {
  post_id: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
  text: Scalars["String"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  has_next_page: Scalars["Boolean"];
  end_cursor?: Maybe<Scalars["String"]>;
};

export type PostComment = {
  __typename?: "PostComment";
  id: Scalars["ID"];
  post_id: Scalars["String"];
  parent_id: Scalars["String"];
  avatar: Scalars["String"];
  name: Scalars["String"];
  text: Scalars["String"];
  updated_at: Scalars["Int"];
  created_at: Scalars["Int"];
};

export type PostCommentConnection = {
  __typename?: "PostCommentConnection";
  edges: Array<Maybe<PostCommentEdge>>;
  page_info: PageInfo;
};

export type PostCommentEdge = {
  __typename?: "PostCommentEdge";
  node: PostComment;
};

export type Query = {
  __typename?: "Query";
  alive?: Maybe<HealthStatus>;
  ready?: Maybe<HealthStatus>;
  getPostComments?: Maybe<PostCommentConnection>;
};

export type QueryGetPostCommentsArgs = {
  post_id: Scalars["String"];
  cursor?: Maybe<Scalars["String"]>;
};

export type Success = {
  __typename?: "Success";
  success: Scalars["Boolean"];
};

export type GetPostCommentsQueryVariables = Exact<{
  post_id: Scalars["String"];
  cursor?: Maybe<Scalars["String"]>;
}>;

export type GetPostCommentsQuery = { __typename?: "Query" } & {
  getPostComments?: Maybe<
    { __typename?: "PostCommentConnection" } & {
      edges: Array<
        Maybe<
          { __typename?: "PostCommentEdge" } & {
            node: { __typename?: "PostComment" } & Pick<
              PostComment,
              | "id"
              | "post_id"
              | "parent_id"
              | "avatar"
              | "name"
              | "text"
              | "updated_at"
              | "created_at"
            >;
          }
        >
      >;
      page_info: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "has_next_page" | "end_cursor"
      >;
    }
  >;
};

export type CreatePostCommentMutationVariables = Exact<{
  post_id: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
  text: Scalars["String"];
}>;

export type CreatePostCommentMutation = { __typename?: "Mutation" } & {
  createPostComment?: Maybe<
    { __typename?: "PostComment" } & Pick<
      PostComment,
      | "id"
      | "post_id"
      | "parent_id"
      | "avatar"
      | "name"
      | "text"
      | "updated_at"
      | "created_at"
    >
  >;
};

export type SubscribeMutationVariables = Exact<{
  email: Scalars["String"];
  source: Scalars["String"];
}>;

export type SubscribeMutation = { __typename?: "Mutation" } & {
  subscribe?: Maybe<{ __typename?: "Success" } & Pick<Success, "success">>;
};

export const GetPostCommentsDocument = gql`
  query getPostComments($post_id: String!, $cursor: String) {
    getPostComments(post_id: $post_id, cursor: $cursor) {
      edges {
        node {
          id
          post_id
          parent_id
          avatar
          name
          text
          updated_at
          created_at
        }
      }
      page_info {
        has_next_page
        end_cursor
      }
    }
  }
`;

/**
 * __useGetPostCommentsQuery__
 *
 * To run a query within a React component, call `useGetPostCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostCommentsQuery({
 *   variables: {
 *      post_id: // value for 'post_id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetPostCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPostCommentsQuery,
    GetPostCommentsQueryVariables
  >
) {
  return Apollo.useQuery<GetPostCommentsQuery, GetPostCommentsQueryVariables>(
    GetPostCommentsDocument,
    baseOptions
  );
}
export function useGetPostCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPostCommentsQuery,
    GetPostCommentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPostCommentsQuery,
    GetPostCommentsQueryVariables
  >(GetPostCommentsDocument, baseOptions);
}
export type GetPostCommentsQueryHookResult = ReturnType<
  typeof useGetPostCommentsQuery
>;
export type GetPostCommentsLazyQueryHookResult = ReturnType<
  typeof useGetPostCommentsLazyQuery
>;
export type GetPostCommentsQueryResult = Apollo.QueryResult<
  GetPostCommentsQuery,
  GetPostCommentsQueryVariables
>;
export const CreatePostCommentDocument = gql`
  mutation createPostComment(
    $post_id: String!
    $email: String!
    $name: String!
    $text: String!
  ) {
    createPostComment(
      post_id: $post_id
      email: $email
      name: $name
      text: $text
    ) {
      id
      post_id
      parent_id
      avatar
      name
      text
      updated_at
      created_at
    }
  }
`;
export type CreatePostCommentMutationFn = Apollo.MutationFunction<
  CreatePostCommentMutation,
  CreatePostCommentMutationVariables
>;

/**
 * __useCreatePostCommentMutation__
 *
 * To run a mutation, you first call `useCreatePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostCommentMutation, { data, loading, error }] = useCreatePostCommentMutation({
 *   variables: {
 *      post_id: // value for 'post_id'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreatePostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePostCommentMutation,
    CreatePostCommentMutationVariables
  >(CreatePostCommentDocument, baseOptions);
}
export type CreatePostCommentMutationHookResult = ReturnType<
  typeof useCreatePostCommentMutation
>;
export type CreatePostCommentMutationResult = Apollo.MutationResult<CreatePostCommentMutation>;
export type CreatePostCommentMutationOptions = Apollo.BaseMutationOptions<
  CreatePostCommentMutation,
  CreatePostCommentMutationVariables
>;
export const SubscribeDocument = gql`
  mutation subscribe($email: String!, $source: String!) {
    subscribe(email: $email, source: $source) {
      success
    }
  }
`;
export type SubscribeMutationFn = Apollo.MutationFunction<
  SubscribeMutation,
  SubscribeMutationVariables
>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeMutation,
    SubscribeMutationVariables
  >
) {
  return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
    baseOptions
  );
}
export type SubscribeMutationHookResult = ReturnType<
  typeof useSubscribeMutation
>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<
  SubscribeMutation,
  SubscribeMutationVariables
>;
