import React, { forwardRef, useContext } from "react";
import { Link } from "gatsby";
import styled, { css, ThemeContext } from "styled-components";

import zIndex from "../styles/zIndex";
import shadow from "../styles/shadow";

interface ContainerProps {
  isHidden?: boolean;
  hasShadow?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 15px 20px;
  width: 100%;
  background: ${(props) => props.theme.color.primary.main};
  z-index: ${zIndex.appBar - 1};
  transition: ${(props) => props.theme.transition.create()};

  ${(props) =>
    props.hasShadow &&
    css`
      box-shadow: ${shadow[5]};
    `}

  ${(props) =>
    props.isHidden &&
    css`
      top: 41px;
    `}
  
  ${(props) => props.theme.breakpoint.down("sm")} {
    top: 41px;
  }
`;

interface StyledLinkProps {
  isHidden?: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  color: ${(props) => props.theme.color.primary.minus};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 1;
  transition: ${(props) => props.theme.transition.create()};
  cursor: pointer;

  & + & {
    padding-left: 15px;
  }

  &:hover {
    color: ${(props) => props.theme.color.secondary.minus};
  }

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0;
    `}

  ${(props) => props.theme.breakpoint.down("sm")} {
    opacity: 0;
  }
`;

interface Props {
  hide?: boolean;
  shadow?: boolean;
}

const Nav = forwardRef<HTMLDivElement, Props>(({ hide, shadow }, ref) => {
  const theme = useContext(ThemeContext);

  const activeStyle = { color: theme.color.secondary.minus };

  return (
    <Container isHidden={hide} hasShadow={shadow} ref={ref}>
      <StyledLink activeStyle={activeStyle} isHidden={hide} to="/">
        Home
      </StyledLink>
      <StyledLink activeStyle={activeStyle} isHidden={hide} to="/about">
        About
      </StyledLink>
    </Container>
  );
});

export default Nav;
