import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface LinkProps {
  hasLabel?: boolean;
}

const Link = styled.a<LinkProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.secondary.main};
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.theme.color.primary.minus};
  border-radius: 3px;
  height: 40px;

  &:hover {
    color: #fff;
    background: linear-gradient(105deg, #11998e, #38ef7d);
    border-color: #28cc84;
  }

  & + & {
    margin-left: 10px;
  }

  ${(props) => {
    if (!props.hasLabel) {
      return css`
        width: 40px;
        justify-content: center;
      `;
    }

    return css`
      ${(props) => props.theme.breakpoint.down("sm")} {
        width: 40px;
        justify-content: center;
      }
    `;
  }}
`;

const Label = styled.span`
  margin-left: 5px;

  ${(props) => props.theme.breakpoint.down("sm")} {
    display: none;
  }
`;

interface Props {
  url: string;
  icon: IconProp;
  label?: string;
}

const SocialLink: React.FC<Props> = ({ url, icon, label }) => {
  return (
    <Link hasLabel={!!label} href={url} rel={"noopener"} target={"_blank"}>
      <FontAwesomeIcon icon={icon} />
      {label && <Label>{label}</Label>}
    </Link>
  );
};

export default SocialLink;
