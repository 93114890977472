import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Spacer = styled.div`
  display: flex;
  width: 100%;
  height: 115px;

  ${(props) => props.theme.breakpoint.down("sm")} {
    height: 80px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  padding: 0 20px;
  width: 100%;

  ${(props) => props.theme.breakpoint.up("sm")} {
    padding: 0 12px;
    width: ${(props) =>
      Math.round(props.theme.breakpoint.width("sm") * 0.975)}px;
  }

  ${(props) => props.theme.breakpoint.up("md")} {
    width: ${(props) =>
      Math.round(props.theme.breakpoint.width("md") * 0.975)}px;
  }

  ${(props) => props.theme.breakpoint.up("lg")} {
    width: ${(props) => Math.round(props.theme.breakpoint.width("lg") * 0.8)}px;
  }
`;

const Content: React.FC = (props) => (
  <Container>
    <Spacer />
    <StyledContent {...props} />
  </Container>
);

export default Content;
