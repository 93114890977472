import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { gray } from "@tleef/colors";

import SvgLogo from "!svg-react-loader?name=Logo!../../content/vectors/logo.svg";

interface ContainerProps {
  noText?: boolean;
}

const Container = styled(Link)<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 46px;
  transition: ${(props) => props.theme.transition.create()};

  ${(props) =>
    props.noText &&
    css`
      height: 30px;
    `}
`;

const StyledLogo = styled(SvgLogo)`
  position: absolute;
  top: 0;
  height: 30px;
  display: block;
`;

interface NameProps {
  isHidden?: boolean;
}

const Name = styled.div<NameProps>`
  position: absolute;
  top: 30px;
  font-size: 16px;
  color: ${gray[400]};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 5px;
  transition: ${(props) => props.theme.transition.create()};

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0;
    `}
`;

interface Props {
  shrink: boolean;
}

const Logo: React.FC<Props> = ({ shrink }) => (
  <Container noText={shrink} to="/">
    <StyledLogo />
    <Name isHidden={shrink}>TLEEF</Name>
  </Container>
);

export default Logo;
