import Typography from "typography";

const typography = new Typography({
  baseFontSize: "20px",
  baseLineHeight: 1.7,
  scaleRatio: 2,
  googleFonts: [
    {
      name: "Karla",
      styles: ["400", "600", "700"],
    },
  ],
  headerFontFamily: ["Karla", "Helvetica", "Arial", "sans-serif"],
  bodyFontFamily: ["Karla", "Helvetica", "Arial", "sans-serif"],
  headerWeight: 700,
  bodyWeight: 400,
  boldWeight: 600,
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
