import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ApolloProvider } from "@apollo/client";
import { Theme } from "../types";
import { rhythm } from "../utils/typography";
import graph from "../backends/graph";

const GlobalStyle = createGlobalStyle`
  html {
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  box-sizing: border-box;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    background-color: ${(props) => props.theme.color.primary.main};
    transition: ${(props) => props.theme.transition.create()};
    @media print {
      background-color: #fff;
    }
  }
  a {
    text-decoration: none;
    color: #28cc84
  }
  a, button {
    outline: none;
  }
  img {
    margin-bottom: 0;
  }
  li {
    margin-bottom: 0;
  }
  blockquote {
    color: ${(props) => props.theme.color.primary.minus};
    margin-left: 0;
    padding-left: ${rhythm(1)};
    border-left: 5px solid ${(props) => props.theme.color.separator};
  }
  h1, h2, h3, strong {
    color: ${(props) => props.theme.color.secondary.main};
  }
  
  .bm-cross-button .bm-cross,
  .bm-burger-button .bm-burger-bars {
    transition: ${(props) => props.theme.transition.create()};
  }
  .bm-cross-button:hover .bm-cross,
  .bm-burger-button:hover .bm-burger-bars {
    background: ${(props) => props.theme.color.secondary.minus} !important;
  }
`;

interface Props {
  theme: Theme;
}

const Root: React.FC<Props> = ({ theme, children }) => (
  <ApolloProvider client={graph.client}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        {children}
      </React.Fragment>
    </ThemeProvider>
  </ApolloProvider>
);

export default Root;
