import React from "react";
import styled from "styled-components";

import zIndex from "../styles/zIndex";

import SocialLinks from "./SocialLinks";

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.color.primary.plus};
  transition: ${(props) => props.theme.transition.create()};
  justify-content: center;
  margin-top: 50px;
  z-index: ${zIndex.appBar - 2};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 40px 20px;
  width: 100%;

  ${(props) => props.theme.breakpoint.up("sm")} {
    width: ${(props) =>
      Math.round(props.theme.breakpoint.width("sm") * 0.975)}px;
  }

  ${(props) => props.theme.breakpoint.up("md")} {
    flex-direction: row;
    justify-content: space-between;
    width: ${(props) =>
      Math.round(props.theme.breakpoint.width("md") * 0.975)}px;
  }

  ${(props) => props.theme.breakpoint.up("lg")} {
    width: ${(props) => Math.round(props.theme.breakpoint.width("lg") * 0.8)}px;
  }
`;

const Copyright = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.color.secondary.main};
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoint.up("md")} {
    margin-bottom: 0;
  }
`;

const Footer: React.FC = () => (
  <Container>
    <Inner>
      <Copyright>Copyright © 2021 TLEEF</Copyright>
      <Links>
        <SocialLinks />
      </Links>
    </Inner>
  </Container>
);

export default Footer;
